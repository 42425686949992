import is from './is';

export default function isValidEntity(entity, schemaDefinition) {
  const schema = Object.assign({}, schemaDefinition, {
    '@@metadata': schemaDefinition['@@metadata'] || {}
  });

  const {
    allowNulls
  } = schema['@@metadata'];

  // Could create error messages and return the list for logging
  const failures = Object.entries(schema).filter(([key, definition]) => {
    if (key === '@@metadata') {
      return false;
    }

    const {
      possibleValues,
      required,
      type
    } = definition;

    const value = entity[key];

    // Test for required first
    if (required &&
        required === true &&
        typeof value === 'undefined') return true;

    if (allowNulls && is.null(value)) return false;

    // Test for correct type next
    if (typeof value !== 'undefined' &&
        !is[type](value)) return key;

    if (possibleValues &&
        possibleValues.indexOf(value) < 0) return true;

    return false;
  });

  if (failures.length > 0) return false;

  return true;
}

