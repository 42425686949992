import React, {Component} from 'react';
import {
  Alert,
  Icon
} from 'antd';
import Api from '../../api';
import GamePredictionsFilter from './GamePredictionsFilter';
import GamePredictionsList from './GamePredictionsList';
import {
  rankSort,
  formatUpcomingGamesResponse
} from './utils';

import './UpcomingGamesList.less';

const alertMessage = "Technical Foul! We're working on it. Try again shortly."

function filterPredictions(filters, predictions) {
  const {gameType, region} = filters;

  return predictions.filter(prediction => {
    const {game_type, tournament_region} = prediction.game_metadata;

    if (gameType && gameType !== game_type) return false;

    if (region && region !== tournament_region) return false;
    
    return true;
  });
}

class UpcomingGamesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {},
      gamePredictions: null,
      isFetchingGames: false,
      requestFailed: false
    };

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  onFilterChange(filters) {
    this.setState({filters});
  }

  async componentDidMount() {
    try {
      this.setState({
        isFetchingGames: true,
        requestFailed: false
      });

      const response = await Api.getUpcomingGamePredictions();
      const formattedResponse = formatUpcomingGamesResponse(response, rankSort);

      this.setState({
        isFetchingGames: false,
        gamePredictions: formattedResponse.game_predictions
      });
    } catch (error) {
      this.setState({
        isFetchingGames: false,
        requestFailed: true
      });
    }
  }

  render() {
    const {
      filters,
      gamePredictions,
      isFetchingGames,
      requestFailed
    } = this.state;

    let content;

    if (isFetchingGames) {
      content = (
        <Icon type='loading' className='loading-icon' />
      );
    }

    if (!isFetchingGames && requestFailed) {
      content = (
        <Alert
          className='network-error'
          message={alertMessage}
          type='error'
        />
      );
    }

    if (!isFetchingGames && gamePredictions && Array.isArray(gamePredictions)) {
      let predictions = filterPredictions(filters, gamePredictions);
      content = (
        <div>
          <GamePredictionsFilter onFilterChange={this.onFilterChange} />
          <GamePredictionsList gamePredictions={predictions} />
        </div>
      );
    }

    return (
      <div className='upcoming-games-list'>
        <h2 className='upcoming-games-list--heading'>
          Upcoming Tournament Game Predictions*
        </h2>

        {content}

        <p style={{marginTop: '24px'}}>
          * At this time the Elo model is used to predict outcomes 
            for all games listed above.
        </p>
      </div>
    );
  }
}

export default UpcomingGamesList;

