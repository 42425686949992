import React from 'react';
import ChartOutputs from './ChartOutputs';


const PredictionsDisplay = (props) => {
  const {predictions} = props;

  return (
    <ChartOutputs predictions={predictions} />
  );
};

export default PredictionsDisplay;
