import React from 'react';
import DefaultSection from './DefaultSection';

import './Subtitle.less';

const Subtitle = () => (
  <DefaultSection sectionClassName='subtitle'>
    <div className='subtitle--heading-container'>
      <h2>DOMINATE YOUR BRACKET WITH PREDICTIVE ANALYTICS</h2>
      <p>
        Dramatically improve your odds of picking winning teams by 
        putting 30+ years of game data, math, and machine learning on your 
        side.
      </p>
    </div>
  </DefaultSection>
);

export default Subtitle;
