import isValidEntity from '../../utils/schemaValidation';
import Schemas from '../../schemas';

const GAME_TYPE_SORT_KEY_ENUM = {
  NCAATournament: '1',
  NIT: '2',
  RegularSeason: '3',
};

// Must have model_name, predicted_winner, probability
function gamePredictionIsValid(prediction) {
  if (!prediction) return false;

  return isValidEntity(prediction, Schemas.UpcomingGamePrediction);
}

function gameMetadataIsValid(gameMetadata) {
  if (!gameMetadata) return false;

  return isValidEntity(gameMetadata, Schemas.UpcomingGameMetadata);
}

function validGames(gamePredictions) {
  return gamePredictions.reduce((accum, gamePrediction) => {
    const {game_metadata, predictions} = gamePrediction;

    if (!predictions) return accum;
    if (!gameMetadataIsValid(game_metadata)) return accum;

    const validPredictions = predictions.filter(gamePredictionIsValid);

    if (validPredictions.length === 0) return accum;

    return accum.concat(Object.assign({}, gamePrediction, {
      predictions: validPredictions
    }));
  }, []);
}

// Get the lowest rank from game metadata
// using a rankKey of 'seed' or 'ranking'.
// If both ranks are null, use home team name
function lowestRank(gameMetadata, rankKey) {
  const teamOneRank = gameMetadata[`team_one_${rankKey}`];
  const teamTwoRank = gameMetadata[`team_two_${rankKey}`];

  if (!teamOneRank && !teamTwoRank) return 999;

  if (!teamOneRank) return teamTwoRank;

  if (!teamTwoRank) return teamOneRank;

  return teamOneRank < teamTwoRank
    ? teamOneRank
    : teamTwoRank;
}

export function rankSort(gamePredictions) {
  return gamePredictions.sort((firstGame, secondGame) => {
    const {game_metadata: firstGameMetadata} = firstGame;
    const {game_metadata: secondGameMetadata} = secondGame;

    const {game_type: firstGameType} = firstGameMetadata;
    const {game_type: secondGameType} = secondGameMetadata;

    const firstGameTypeSortKey = GAME_TYPE_SORT_KEY_ENUM[firstGameType];
    const secondGameTypeSortKey = GAME_TYPE_SORT_KEY_ENUM[secondGameType];

    const firstGameRankKey = firstGameType === 'RegularSeason'
      ? 'ranking'
      : 'seed';
    const secondGameRankKey = secondGameType === 'RegularSeason'
      ? 'ranking'
      : 'seed';

    const firstGameLowestRank = String(lowestRank(
      firstGameMetadata,
      firstGameRankKey
    ));

    const secondGameLowestRank = String(lowestRank(
      secondGameMetadata,
      secondGameRankKey
    ));

    const firstGameSortKey = Number(`${firstGameTypeSortKey}${firstGameLowestRank}`);
    const secondGameSortKey = Number(`${secondGameTypeSortKey}${secondGameLowestRank}`);

    if (firstGameSortKey < secondGameSortKey) return -1;

    if (firstGameSortKey === secondGameSortKey) return 0;

    return 1;
  });
}

export function formatUpcomingGamesResponse(response, sortFn) {
  const defaultResponse = {
    game_predictions: []
  };

  if (!response ||
      !response.game_predictions ||
      !Array.isArray(response.game_predictions)) return defaultResponse;

  let games = validGames(response.game_predictions);

  if (sortFn) games = sortFn(games);

  return Object.assign({}, response, {
    game_predictions: games
  });
}

