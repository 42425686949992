import React from 'react';
import DefaultSection from './DefaultSection';

import './HeroV2.less';
import logo from '../assets/images/HoopAnator_V1_03.png';

const HeroV2 = () => {
  return (
    <DefaultSection
      sectionClassName='hero'
      centerColumnClassName='hero--center-column'
    >
      <div className='hero--logo-container'>
        <img
          alt='The Hoop A-Nator logo'
          src={logo}
        />
      </div>
    </DefaultSection>
  );
};

export default HeroV2;
