import React from 'react';
import {Card, Col} from 'antd';
import Analytics from '../analytics';

import "./BlogPreview.less";

const analytics = Analytics();

const BlogPreview = (props) => {
  const {
    href,
    imgSrc,
    imgAlt,
    title
  } = props;

  const img = require(`../assets/images/${imgSrc}`);

  return (
    <Col lg={8} md={12} className='blog-preview'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={href}
        onClick={() => {
          analytics.trackEvoLink(href.split('.com')[1]);
        }}
      >
        <Card
          cover={<img alt={imgAlt} src={img} />}
          hoverable
        >
          {title}
        </Card>
      </a>
    </Col>
  );
};

export default BlogPreview;

