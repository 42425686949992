const UpcomingGamePrediction = {
  is_close: {
    type: 'boolean'
  },
  model_name: {
    type: 'string',
    required: true
  },
  predicted_winner: {
    type: 'string',
    required: true
  },
  probability: {
    type: 'number',
    required: true
  }
};

export default UpcomingGamePrediction;

