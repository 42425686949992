import React from 'react';

const Predictions = ({predictions}) => {
  return (
    <div className='game-predictions-list--predictions'>
      {predictions.map(prediction => {
        const {
          model_name,
          predicted_winner,
          probability
        } = prediction;

        return (
          <React.Fragment key={model_name}>
            <p>
              <span className='label'>
                Predicted Winner:
              </span>
              {' '}
              {predicted_winner}
            </p>

            <p>
              <span className='label'>
                Probability:
              </span>
              {' '}
              {probability}%
            </p>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Predictions;

