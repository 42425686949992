import React from 'react';
import DefaultSection from './DefaultSection';

import './HubspotEmailForm.less';

class HubspotEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hsScriptIsLoaded: false
    };
  }

  componentDidMount() {
    const scriptId = 'hubspot-forms-script';
    const existingScript = document.getElementById(scriptId);

    if (!!existingScript) return;

    // Stub jQuery so the form submitted callbacks work
    window.jQuery = window.jQuery || function(nodeOrSelector) {
      if (typeof nodeOrSelector === 'string') {
        return document.querySelector(nodeOrSelector);
      }
      return nodeOrSelector;
    };

    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = () => {
      this.setState({hsScriptIsLoaded: true});
      window.hbspt.forms.create({
        portalId: '8991984',
        formId: 'b5c947c3-2508-40e6-813a-7d9a41870161',
        target: '.hubspot-email-form-container',
        onFormSubmitted: function() {
          // Requires jQuery
          console.log(arguments);
          console.log('Form Submitted');
        },
      });
    };
  }

  render() {
    const {
      hsScriptIsLoaded
    } = this.state;

    if (!hsScriptIsLoaded) return null;

    return (
      <DefaultSection sectionClassName='hubspot-email-form-section'>
        <div className='hubspot-email-form-container'></div>

        <div className='fine-print'>
          <p>
            By entering your email address, you consent to receive communications from Evolytics. 
            You may unsubscribe at any time by clicking the unsubscribe link located at the bottom of any email. 
            This site is protected by reCAPTCHA and the Google&nbsp;
            <a href='https://policies.google.com/privacy'>Privacy Policy</a>&nbsp;
            and&nbsp;
            <a href='https://policies.google.com/terms'>Terms of Service</a>&nbsp;
            apply.
          </p>
        </div>
      </DefaultSection>
    );
  }
}

export default HubspotEmailForm;
