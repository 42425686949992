import React from 'react';
import {List, Card} from 'antd';
import Matchup from './Matchup';
import Predictions from './Predictions';
import Analytics from '../../analytics';

const analytics = Analytics();

const GamePredictionsList = ({gamePredictions}) => {
  if (gamePredictions.length === 0) {
    return (
      <p>No upcoming game predictions available.</p>
    );
  }

  let hasUsedPagination = false;

  return (
    <div>
      <List
        className='game-predictions-list'
        grid={{
          gutter: 16,
          md: 1,
          lg: 2
        }}
        dataSource={gamePredictions}
        pagination={{
          onChange: (page) => {
            setTimeout(function() {
              document.querySelector('.upcoming-games-list--heading')
                .scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest'
                });
            }, 100);

            if (!hasUsedPagination) {
              analytics.track('event', 'click', {
                event_category: 'SPS Pagination',
                event_label: 'usage'
              });

              hasUsedPagination = true;
            }
          },
          pageSize: 8
        }}
        renderItem={item => {
          return (
            <List.Item>
              <Card className='game-predictions-list--card'>
                <Matchup gameMetadata={item.game_metadata} />
                <Predictions predictions={item.predictions} />
              </Card>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default GamePredictionsList;

