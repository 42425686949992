import React from 'react';
import DefaultSection from './DefaultSection';
import ExitLink from './ExitLink';

import './AboutSection.less';

const AboutSection = (props) => {
  return (
    <div className='about-section'>
      <DefaultSection sectionClassName='about-section--what'>
        <div className='about-section--divider-wrapper'>
          <div></div>
        </div>

        <h2>
          How do I Interpret Predicted Winner and Probability?
        </h2>

        <p>
          The predicted winner is the team who our model believes has a 
          greater than 50% chance of winning in the given match.
        </p>

        <p>
          It’s important to note here that we can never be 100% 
          sure when we’re trying to predict the future. The model 
          is based upon a variety of historical factors, and you’ll 
          find it to be fairly accurate, especially when the model 
          probability was confident. We would define confident as 
          having a win probability greater than 90 percent.
        </p>

        <p>
          When the win probability is nearer the 50% line, it’s going 
          to be a good game and has high upset potential. The model 
          is predicting that these teams are actually fairly evenly matched. 
          When factors are fairly equal going into a match, the unpredictable 
          human nature of sports increases in importance and comes into play.
        </p>

        <h2>
            What is Hoop-A-Nator?
        </h2>

        <p>
          Put simply, Hoop-A-Nator is a tool that uses 
          predictive analytics to forecast which team would win in a matchup.
        </p>

        <p>
          This collaborative, cross-functional project was created by a team of 
          developers, data engineers, statisticians, and sports fanatics. 
          This machine learning model is based on the Elo rating system, 
          trained through the regular season in order to predict tournament results.
        </p>

        <p>
          We wanted this to be a way to give back to the data science 
          community, so you can also read details and documentation 
          about the methodology behind our model.
        </p>

        <div className='about-section--heading-wrapper'>
          <h2>Why the Elo Rating System?</h2>
        </div>

        <p>
          Originally created by Physics Professor Arpad Elo to predict the likely 
          outcome of chess games, the Elo rating system has evolved to calculate 
          the relative skill of competitors, therefore forecasting a likely winner, 
          in all sorts of zero sum games.
        </p>

        <p>
          We developed our own mathematical model and continually worked to 
          increase the accuracy of the basic Elo foundation. We would like to 
          credit Nate Silver of &nbsp;
          <ExitLink
            href='https://fivethirtyeight.com/features/how-we-calculate-nba-elo-ratings/'>
            FiveThirtyEight
          </ExitLink>
          , whose NBA Elo calculations were an inspiration for this particular model.
        </p>
      </DefaultSection>
    </div>
  );
};

export default AboutSection;

