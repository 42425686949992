import React from 'react';
import {Select} from 'antd';
import teams from '../constants/teams';

import './TeamSelect.less';

const Option = Select.Option;

const selectFilter = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

const TeamSelect = ({handleChange, label, teamNumber}) => {
  return (
    <div className={`team-select team-${teamNumber}-select`}>
      {label && (
        <div>
          <label>{label}</label>
        </div>
      )}

      <Select
        allowClear
        className='team-select--input'
        dropdownClassName={`team-select--dropdown team-${teamNumber}-select--dropdown`}
        dropdownStyle={{
          fontFamily: 'Graduate',
        }}
        filterOption={selectFilter}
        onChange={handleChange}
        optionFilterProp='children'
        placeholder='Select a team'
        showSearch
        size='large'
      >
        {teams.alphabetical.map(team => {
          const {teamName, displayName, teamId} = team;
          return (
            <Option
              key={teamName}
              value={`${teamName}|${displayName}|${teamId}`}
            >
              {displayName}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default TeamSelect;

