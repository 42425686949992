import 'isomorphic-fetch';
import {API_CONFIG} from '../config';

export function createApiRequest(fetch, apiRoot, endpoint, method = 'GET') {
  if (!fetch) throw new Error('A fetch implementation is required.');

  if (!apiRoot) throw new Error('An apiRoot is required.');

  if (!endpoint) throw new Error('An API endpoint is required.');

  return async function(options = {}) {
    try {
      let url = `${apiRoot}${endpoint}`;

      // Append full query string onto url
      if (options.queryParams) {
        url += options.queryParams;
      }

      // Replace :param in endpoint with each uriParam
      if (options.uriParams) {
        for (let param in options.uriParams) {
          url = url.replace(param, options.uriParams[param]);
        }
      }

      let params = {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      };

      if (options.body) {
        params = Object.assign({}, params, {
          body: JSON.stringify(options.body)
        });
      }

      const response = await fetch(url, params);

      if (!response.ok) {
        throw new Error(`Status Code: ${response.status}. ${response.statusText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      throw error;
    }
  }
}

const apiRequests = {};

API_CONFIG.forEach(api => {
  const {rootUrl, routes} = api;

  routes.forEach(route => {
    const {endpoint, method, name} = route;

    apiRequests[name] = createApiRequest(fetch, rootUrl, endpoint, method);
  });
});

export default apiRequests;

