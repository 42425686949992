module.exports = (function is() {
  var toString = Object.prototype.toString;
  var BOOLEAN = '[object Boolean]';
  var STRING = '[object String]';
  var NUMBER = '[object Number]';
  var ARRAY = '[object Array]';
  var OBJECT = '[object Object]';
  var FUNCTION = '[object Function]';
  var NULL = '[object Null]';
  var UNDEFINED = '[object Undefined]';

  function prepareTypeCheck(type) {
    return function(item) {
      return toString.call(item) === type;
    }
  }

  return {
    array: prepareTypeCheck(ARRAY),
    object: prepareTypeCheck(OBJECT),
    'function': prepareTypeCheck(FUNCTION),
    'null': prepareTypeCheck(NULL),
    'undefined': prepareTypeCheck(UNDEFINED),
    string: prepareTypeCheck(STRING),
    number: prepareTypeCheck(NUMBER),
    'boolean': prepareTypeCheck(BOOLEAN),
    nil: function(item) {
      return this.null(item) || this.undefined(item);
    }
  };
})();

