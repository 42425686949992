import React from 'react';
import {Row} from 'antd';
import DefaultSection from './DefaultSection';
import BlogPreview from './BlogPreview';

import './BlogSection.less';

const BlogSection = ({blogs}) => {
  return (
    <DefaultSection sectionClassName='blog-section'>
      <h2>Blogs</h2>

      <Row gutter={[16, 16]}>
        {blogs.map(blog => (
          <BlogPreview key={blog.title} {...blog} />
        ))}
      </Row>
    </DefaultSection>
  );
};

export default BlogSection;

