import React, {Component} from 'react';
import {
  Alert,
  Button,
  Form,
  Icon,
  Row
} from 'antd';
import TeamSelect from '../../components/TeamSelect';
import Api from '../../api';
import Analytics from '../../analytics';
import PredictionsDisplay from './PredictionsDisplay';
import {formatPredictions} from './utils';

import './MatchupPredictorV2.less';

const teamOneColor = '#f7931d';
const teamTwoColor = '#0082ca';

const FormItem = Form.Item;
const analytics = Analytics();

const GoButton = ({
  className,
  getPredictions,
  isFetchingPredictions,
}) => {
  let el;

  if (isFetchingPredictions) {
    el = (
      <div className='matchup-requesting-spinner'>
        <Icon type='loading' />
      </div>
    );
  } else {
    el = (
      <Button
        className='matchup-submit-button'
        onClick={getPredictions}
      >
        GO
      </Button>
    );
  }

  return (
    <FormItem className={className}>
      {el}
    </FormItem>
  );
};


class MatchupPredictorV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      isInvalid: false,
      isFetchingPredictions: false,
      minTeamId: null,
      maxTeamId: null,
      predictions: null,
      shouldShowAlert: false,
      shouldShowResult: false,
      teamOne: null,
      teamOneDisplayName: null,
      teamOneId: null,
      teamTwo: null,
      teamTwoDisplayName: null,
      teamTwoId: null,
    };

    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.handleTeamOneChange = this.handleTeamOneChange.bind(this);
    this.handleTeamTwoChange = this.handleTeamTwoChange.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleTechnicalError = this.handleTechnicalError.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.getPredictions = this.getPredictions.bind(this);
  }

  handleTeamChange(team, value) {
    this.setState({
      shouldShowResult: false,
    })
    if (!value) {
      return this.setState({
        [team]: null,
        [team + 'DisplayName']: null
      });
    }

    const [teamName, displayName, teamId] = value.split('|');

    this.setState({
      [team]: teamName,
      [team + 'DisplayName']: displayName,
      [team + 'Id']: teamId
    });
  }

  handleTeamOneChange(value) {
    this.handleTeamChange('teamOne', value);
  }

  handleTeamTwoChange(value) {
    this.handleTeamChange('teamTwo', value);
  }

  handleAlertClose(event) {
    this.setState({ shouldShowAlert: false });
  }

  handleValidation() {
    const {
      teamOne,
      teamTwo,
      teamOneId,
      teamTwoId
    } = this.state;

    if (teamOne && teamTwo && teamOneId && teamTwoId) {
      this.setState({
        alertMessage: '',
        isInvalid: false,
        shouldShowAlert: false
      });
      return true;
    }

    this.setState({
      alertMessage: 'Personal Foul! Select two teams and try again.',
      isInvalid: true,
      shouldShowAlert: true
    });
    return false;
  }

  handleTechnicalError() {
    const {teamOne, teamTwo} = this.state;

    analytics.trackModelEvent(
      'Matchup Predictor',
      'Prediction Error',
      `${teamOne}|${teamTwo}`
    );

    this.setState({
      alertMessage: "Technical Foul! We're working on it. Try again shortly.",
      isFetchingPredictions: false,
      shouldShowAlert: true
    });
  };

  async getPredictions() {
    const {
      teamOne,
      teamOneDisplayName,
      teamOneId,
      teamTwo,
      teamTwoId,
      teamTwoDisplayName
    } = this.state;

    try {
      if (!this.handleValidation()) return;

      const minTeamId = teamOneId <= teamTwoId ? teamOneId : teamTwoId;
      const maxTeamId = teamOneId > teamTwoId ? teamOneId : teamTwoId;
      const matchupId = `${String(minTeamId)}${String(maxTeamId)}`;

      this.setState({isFetchingPredictions: true, shouldShowResult: false});

      const result = await Api.getMatchupPredictions({
        uriParams: {
          ':matchupId': matchupId
        }
      });

      if (result.status !== 200) return this.handleTechnicalError();

      const predictions = JSON.parse(result.body).Predictions;
      this.setState({
        isFetchingPredictions: false,
        minTeamId,
        maxTeamId,
        predictions: formatPredictions({
          height: 300,
          width: 600,
          maxTeamId,
          minTeamId,
          predictions,
          teamOneColor,
          teamOneDisplayName,
          teamOneId,
          teamTwoColor,
          teamTwoDisplayName,
          teamTwoId
        }),
        shouldShowResult: true,
      });

      document.querySelector('.matchup-predictor-form--inputs').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });

      // TODO: Lets make this evented and move analytics out
      analytics.trackModelEvent(
        'Matchup Predictor',
        'Prediction Success',
        `${teamOne}|${teamTwo}`
      );
    } catch (err) {
      this.handleTechnicalError();
    }
  }

  render() {
    const {
      alertMessage,
      isFetchingPredictions,
      predictions,
      shouldShowAlert,
      shouldShowResult,
    } = this.state;

    return (
      <Form className='matchup-predictor-form' layout='inline'>
        <Row className='matchup-predictor-form--inputs'>
          <FormItem>
            <TeamSelect teamNumber='one' handleChange={this.handleTeamOneChange} />
          </FormItem>

          <GoButton
            className='desktop-go-button'
            getPredictions={this.getPredictions}
            handleReset={this.handleReset}
            isFetchingPredictions={isFetchingPredictions}
          />

          <FormItem>
            <TeamSelect teamNumber='two' handleChange={this.handleTeamTwoChange} />
          </FormItem>

          <GoButton
            className='mobile-go-button'
            getPredictions={this.getPredictions}
            isFetchingPredictions={isFetchingPredictions}
          />
        </Row>

        {shouldShowAlert && (
          <Row className='matchup-predictor-form--alerts'>
            <Alert
              closable
              message={alertMessage}
              onClose={this.handleAlertClose}
              type='error'
            />
          </Row>
        )}

        {!isFetchingPredictions && shouldShowResult && (
          <Row className='matchup-predictor-form--outputs'>
            <PredictionsDisplay predictions={predictions} />
          </Row>
        )}
      </Form>
    );
  }
}

export default MatchupPredictorV2;
