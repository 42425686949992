import React from 'react';

function Team(props) {
  const {
    name,
    color = 'black'
  } = props;

  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <div
        style={{
          padding: '8px 16px',
          color: 'white',
          backgroundColor: color,
        }}
      >
        <div>{name}</div>
      </div>
    </div>
  );
}

function ModelName(props) {
  const {
    height,
    modelName
  } = props;
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: height,
        padding: '0 8px'
      }}
    >
      {modelName}
    </div>
  );
}

function getBarColor(t1WinProb, t1Color, t2WinProb, t2Color) {
  if (t1WinProb > t2WinProb) return t1Color;
  if (t1WinProb < t2WinProb) return t2Color;
  return 'black';
}

function VBar(props) {
  const {
    height,
    width,
    teamOneColor,
    teamOneName,
    teamOneWinProbability,
    teamTwoColor,
    teamTwoName,
    teamTwoWinProbability,
    modelName
  } = props;

  const midpointX = width / 2;
  const midpointY = height / 2;
  const thirdOfHeight = height / 3;
  const barHeight = thirdOfHeight;
  const barYOffset = thirdOfHeight;
  const teamOneXOffset = teamTwoWinProbability >= teamOneWinProbability
    ? midpointX - ((width * teamOneWinProbability) / 2)
    :midpointX - ((width * teamOneWinProbability) / 2);
  const teamOneBarWidth =  (width * teamOneWinProbability) / 2;
  const teamTwoBarWidth = (width * teamTwoWinProbability) / 2;
  const barColor = getBarColor(teamOneWinProbability, teamOneColor,
                               teamTwoWinProbability, teamTwoColor);
  const percentWinTextOffset = 40;
  const barWidthScalar = 1.4;
  const realBarHeight = barHeight * barWidthScalar;

  return (
    <div
      className='vertical-bar'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Team
        name={teamOneName}
        height={height}
        color={teamOneColor}
      />

      <div style={{textAlign: 'center', margin:'16%'}}>
        <img
          style={{
            maxWidth:'100%',
            transform: 'rotate(90deg)',
            margin: '16px 0'
          }}
          alt='Vertical bar chart displaying the win probability for each team.'
          src={'data:image/svg+xml;base64,' + window.btoa(
            `<svg viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg" fill="none">
              <line
                x1="${midpointX}"
                x2="${midpointX}"
                y1="${20}"
                y2="${height-20}"
                stroke="${barColor}"
                strokeWidth="6"
              />
              <line
                x1="0"
                x2="${width}"
                y1="${midpointY}"
                y2="${midpointY}"
                stroke="gray"
                strokeWidth="2"
                strokeDasharray="2"
              />
              <rect
                x="${teamOneXOffset}"
                y="${midpointY-(realBarHeight/2)}"
                width="${teamOneBarWidth}"
                height="${realBarHeight}"
                fill="${teamOneColor}"
              />
              <rect
                x="${midpointX}"
                y="${midpointY-(realBarHeight/2)}"
                width="${teamTwoBarWidth}"
                height="${realBarHeight}"
                fill="${teamTwoColor}"
              />
              <text
                style="font:bold 32px sans-serif"
                transform="rotate(270, ${midpointX}, ${barHeight})"
                x="${(midpointX/2)-percentWinTextOffset}"
                y="${barYOffset-5}"
                fill="${teamOneColor}"> ${(teamOneWinProbability * 100).toFixed()}%
              </text>
              <text
                style="font:bold 32px sans-serif"
                transform="rotate(270, ${midpointX}, ${barHeight})"
                x="${(midpointX/2)-percentWinTextOffset}"
                y="${barYOffset+30}"
                fill="${teamTwoColor}"> ${(teamTwoWinProbability * 100).toFixed()}%
              </text>
            </svg>`
          )}
        />
      </div>

      <Team
        name={teamTwoName}
        height={height}
        color={teamTwoColor}
      />

      {modelName && (<ModelName height={height} modelName={modelName} />)}
    </div>
  );
}

export default VBar;

