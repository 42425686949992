import React from 'react';
import FeatureFlags from '../feature-flags';
import Layout from '../components/layout';
import Hero from '../components/HeroV2';
import Subtitle from '../components/Subtitle';
import FeaturedModelSection from '../components/FeaturedModelSection';
import UpcomingGamesSection from '../components/UpcomingGamesSection';
import AboutSection from '../components/AboutSection';
import FAQSection from '../components/FAQSection';
import BlogSection from '../components/BlogSection';
import MatchupPredictorV2 from '../containers/MatchupPredictorV2';
import HubspotEmailForm from '../components/HubspotEmailForm';

import faqs from '../constants/faqs';
import blogs from '../constants/blogs';

const Home = () => {
  return (
    <Layout>
      <Hero />

      <Subtitle />

      <FeaturedModelSection>
        <MatchupPredictorV2 />
      </FeaturedModelSection>

      <HubspotEmailForm />

      <AboutSection />

      {FeatureFlags.get('SPSTournament') && (
        <UpcomingGamesSection />
      )}

      <FAQSection faqs={faqs} />

      {FeatureFlags.get('ShowBlogSection') && (
        <BlogSection blogs={blogs} />
      )}
    </Layout>
  );
};

export default Home;

