const MultiModelResponse = {
  models: {
    type: 'array'
  },
  games: {
    type: 'array',
    required: true
  }
};

export default MultiModelResponse;

