import React, {Component} from 'react';
import {Select} from 'antd';

const Option = Select.Option;

class GamePredictionsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameType: 'NCAATournament',
      region: undefined,
      regionIsDisabled: false
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onGameTypeChange = this.onGameTypeChange.bind(this);
    this.onRegionChange = this.onRegionChange.bind(this);
  }

  onFilterChange(value) {
    const {gameType, region} = this.state;
    const {onFilterChange} = this.props;

    if (onFilterChange) onFilterChange({gameType, region});
  }

  onGameTypeChange(value) {
    this.setState({
      gameType: value,
      region: undefined,
      regionIsDisabled: value !== 'NCAATournament'
    }, () => this.onFilterChange());
  }

  onRegionChange(value) {
    this.setState({region: value}, () => this.onFilterChange());
  }

  render() {
    const {
      region,
      regionIsDisabled
    } = this.state;

    return (
      <div className='upcoming-games-list--filters'>
        <Select
          allowClear
          className='select'
          defaultValue='NCAATournament'
          dropdownClassName='filter-dropdown'
          onChange={this.onGameTypeChange}
          placeholder='Tournament Type'
          size='large'
        >
          <Option
            key='NCAATournament'
            value='NCAATournament'
          >
            NCAA Tournament
          </Option>

          <Option key='NIT' value='NIT'>NIT</Option>
        </Select>

        <Select
          allowClear
          className='select'
          disabled={regionIsDisabled}
          dropdownClassName='filter-dropdown'
          onChange={this.onRegionChange}
          placeholder='Region'
          size='large'
          value={region}
        >
          <Option key='East' value='East'>East</Option>
          <Option key='Midwest' value='Midwest'>Midwest</Option>
          <Option key='South' value='South'>South</Option>
          <Option key='West' value='West'>West</Option>
        </Select>
      </div>
    );
  }
}

export default GamePredictionsFilter;

