import MultiModelResponse from './MultiModelResponse';
import MultiModelGameMetadata from './MultiModelGameMetadata';
import MultiModelPrediction from './MultiModelPrediction';

export default {
  MultiModelResponse,
  MultiModelGameMetadata,
  MultiModelPrediction
};

