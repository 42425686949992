const UpcomingGameMetadata = {
  '@@metadata': {
    allowNulls: true
  },
  date: {
    type: 'string',
    required: true
  },
  home_team: {
    type: 'number',
    required: true
  },
  game_type: {
    type: 'string',
    required: true,
    possibleValues: ['NIT', 'NCAATournament', 'RegularSeason']
  },
  team_one_id: {
    type: 'number'
  },
  team_one_name: {
    type: 'string',
    required: true
  },
  team_one_conference: {
    type: 'string',
    required: true
  },
  team_one_ranking: {
    type: 'number'
  },
  team_one_seed: {
    type: 'number'
  },
  team_two_id: {
    type: 'number'
  },
  team_two_name: {
    type: 'string',
    required: true
  },
  team_two_conference: {
    type: 'string',
    required: true
  },
  team_two_ranking: {
    type: 'number'
  },
  team_two_seed: {
    type: 'number'
  },
  tournament_region: {
    type: 'string'
  },
  tournament_round: {
    type: 'number'
  }
};

export default UpcomingGameMetadata;

