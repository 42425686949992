import React from 'react';
import {Col, Row} from 'antd';

import './DefaultSection.less';

const DefaultSection = ({
  centerColumnClassName,
  children,
  sectionClassName,
}) => {
  return (
    <Row className={`default-section ${sectionClassName || ''}`}>
      <Col sm={0} md={3} />

      <Col sm={24} md={18} className={centerColumnClassName || ''}>
        {children}
      </Col>

      <Col span={0} md={3} />
    </Row>
  );
};

export default DefaultSection;

