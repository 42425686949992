import React from 'react';
import DefaultSection from './DefaultSection';

import UpcomingGamesList from '../containers/UpcomingGamesList';

import './UpcomingGamesSection.less';

const UpcomingGamesSection = ({children}) => {
  return (
    <DefaultSection sectionClassName='upcoming-games'>
      <UpcomingGamesList />
    </DefaultSection>
  );
};

export default UpcomingGamesSection;

