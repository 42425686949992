const MultiModelGameMetadata = {
  team_one_id: {
    type: 'number',
    required: true
  },
  team_one_name: {
    type: 'string',
    required: true
  },
  team_two_id: {
    type: 'number',
    required: true
  },
  team_two_name: {
    type: 'string',
    required: true
  }
};

export default MultiModelGameMetadata

