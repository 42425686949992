export const bayesianDescription = `The Bayesian model simulates the possible scores of each team 500 times given multiple years’ worth of past data to capture the most likely point spread, total points, and winner/loser.`;
export const eloDescription = `Originally created by Physics Professor Arpad Elo to predict the likely outcome of chess games, the Elo rating system has evolved to calculate the relative skill of competitors, therefore forecasting a likely winner, in all sorts of zero sum games. We developed our own mathematical model and continually worked to increase the accuracy of the basic Elo foundation.`;

function minMaxToTeamProbabilities(idsAndProbabilities) {
  const {
    minTeamId,
    minTeamWinProbability,
    maxTeamWinProbability,
    teamOneId,
  } = idsAndProbabilities;
  const teamOneWinProbability = minTeamId === teamOneId
    ? +minTeamWinProbability.toFixed(2)
    : +maxTeamWinProbability.toFixed(2);
  const teamTwoWinProbability = minTeamId === teamOneId
    ? +maxTeamWinProbability.toFixed(2)
    : +minTeamWinProbability.toFixed(2);

  return {
    teamOneWinProbability,
    teamTwoWinProbability
  };
}

export function formatPredictions(predictionData) {
  const {
    height,
    width,
    minTeamId,
    maxTeamId,
    predictions,
    teamOneColor,
    teamOneDisplayName,
    teamOneId,
    teamTwoColor,
    teamTwoDisplayName,
    teamTwoId
  } = predictionData;
  const commonProps = {
    height,
    width,
    teamOneColor,
    teamOneName: teamOneDisplayName,
    teamTwoColor,
    teamTwoName: teamTwoDisplayName
  };
  const result = [];


  if (predictions.Bayesian) {
    const {
      MinTeamWinProbability,
      MaxTeamWinProbability,
      GameTotalPoints,
      MinTeamPointSpread
    } = predictions.Bayesian;
    const probs = minMaxToTeamProbabilities({
      minTeamId,
      maxTeamId,
      minTeamWinProbability: MinTeamWinProbability,
      maxTeamWinProbability: MaxTeamWinProbability,
      teamOneId,
      teamTwoId
    });
    let teamOneSpread;
    let teamTwoSpread;

    if (minTeamId === maxTeamId) {
      teamOneSpread = 0;
      teamTwoSpread = 0;
    } else if (probs.teamOneWinProbability > probs.teamTwoWinProbability) {
      teamOneSpread = -1 * Math.abs(MinTeamPointSpread).toFixed(2);
      teamTwoSpread = Math.abs(MinTeamPointSpread).toFixed(2);
    } else if (probs.teamTwoWinProbability > probs.teamOneWinProbability) {
      teamTwoSpread = -1 * Math.abs(MinTeamPointSpread).toFixed(2);
      teamOneSpread = Math.abs(MinTeamPointSpread).toFixed(2);
    }

    result.push({
      ...commonProps,
      description: bayesianDescription,
      modelName: 'BAYESIAN MODEL',
      teamOneSpread: Number(teamOneSpread),
      teamOneWinProbability: probs.teamOneWinProbability,
      teamTwoSpread: Number(teamTwoSpread),
      teamTwoWinProbability: probs.teamTwoWinProbability,
      totalPoints: GameTotalPoints,
    });
  }

  if (predictions.Elo) {
    const {
      MinTeamWinProbability,
      MaxTeamWinProbability
    } = predictions.Elo;
    const probs = minMaxToTeamProbabilities({
      minTeamId,
      maxTeamId,
      minTeamWinProbability: MinTeamWinProbability,
      maxTeamWinProbability: MaxTeamWinProbability,
      teamOneId,
      teamTwoId
    });

    result.push({
      ...commonProps,
      description: eloDescription,
      modelName: 'ELO MODEL',
      teamOneSpread: null,
      teamOneWinProbability: probs.teamOneWinProbability,
      teamTwoSpread: null,
      teamTwoWinProbability: probs.teamTwoWinProbability,
      totalPoints: null
    });
  }

  return result;
}
