import React from 'react';

const NCAA_TOURNAMENT_ROUND_ENUM = {
  0: 'First Four',
  1: 'First Round',
  2: 'Second Round',
  3: 'Sweet 16',
  4: 'Elite Eight',
  5: 'Final Four',
  6: 'Championship'
};

const NIT_TOURNAMENT_ROUND_ENUM = {
  1: 'First Round',
  2: 'Second Round',
  3: 'Quarterfinals',
  4: 'Semifinals',
  5: 'Championship'
};

function getTournamentRound(lookup, round) {
  const result = lookup[round];

  if (result) return result;

  return '';
}

function getGameLabel(gameType, round = '', region = '') {
  if (gameType === 'NCAATournament') {
    const roundLabel = getTournamentRound(
      NCAA_TOURNAMENT_ROUND_ENUM,
      round
    );

    return `NCAA Tournament - ${region} - ${roundLabel}`;
  }

  if (gameType === 'NIT') {
    const roundLabel = getTournamentRound(
      NIT_TOURNAMENT_ROUND_ENUM,
      round
    );

    return `NIT - ${roundLabel}`;
  }

  return '';
}

const Matchup = ({gameMetadata}) => {
  const {
    date,
    game_type,
    home_team,
    team_one_name,
    team_one_ranking,
    team_one_seed,
    team_two_name,
    team_two_ranking,
    team_two_seed,
    tournament_region,
    tournament_round
  } = gameMetadata;

  const separator = home_team === 0
    ? 'vs.'
    : 'at';

  let teamOneTitle;
  let teamTwoTitle;
  let gameLabel = getGameLabel(
    game_type,
    tournament_round,
    tournament_region
  );

  if (game_type === 'RegularSeason') {
    teamOneTitle = team_one_ranking
      ? `#${team_one_ranking} ${team_one_name}`
      : team_one_name;

    teamTwoTitle = team_two_ranking
      ? `#${team_two_ranking} ${team_two_name}`
      : team_two_name;
  }

  if (game_type === 'NCAATournament' || game_type === 'NIT') {
    teamOneTitle = team_one_seed
      ? `#${team_one_seed} ${team_one_name}`
      : team_one_name;

    teamTwoTitle = team_two_seed
      ? `#${team_two_seed} ${team_two_name}`
      : team_two_seed;
  }

  return (
    <div className='game-predictions-list--matchup'>
      <time>{date}</time>
      {' '}
      {gameLabel}
      {' '}
      <div className='title'>
        {teamTwoTitle} {separator} {teamOneTitle}
      </div>
    </div>
  );
};

export default Matchup;

