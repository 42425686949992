import React from 'react';

const axisColor = '#000';
const backgroundBarFill = '#d1d3d4';
const width = 600;
const height = 56;
const textContainerWidth = 80;
const axisStrokeWidth = 1;
const midpointX = width / 2;
const xAxisPosition = height - axisStrokeWidth;
const barYDepth = height * 0.06;
const barYHeight = (height * 0.94) - 1;
const backgroundRectWidth = width - (2 * textContainerWidth);
const gridLineInterval = (width - (2 * textContainerWidth)) / 8;

const teamNameFontSize = '16px';
const predictionFontSize = '32px';
const predictionFontFamily = 'Roboto, sans-serif';
const spreadYOffset = height - 40;
const spreadFontSize = '16px';
const spreadFontFamily = 'Roboto, sans-serif';

const BarV2 = (props) => {
  const {
    teamOneColor,
    teamOneName,
    teamOneSpread,
    teamOneWinProbability,
    teamTwoColor,
    teamTwoName,
    teamTwoSpread,
    teamTwoWinProbability
  } = props;
  const teamOneRectXOffset = textContainerWidth + ((midpointX - textContainerWidth) * (1 - teamOneWinProbability));
  const teamOneRectWidth = (midpointX - textContainerWidth) * teamOneWinProbability;
  const teamTwoRectWidth = (midpointX - textContainerWidth) * teamTwoWinProbability;

  return (
    <div className='bar-chart-v2'>
      <svg
        viewBox={`0 0 ${width} ${height + 20}`}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
      >
        {/* Team One Spread */}
        {teamOneSpread && teamOneSpread < teamTwoSpread && (
          <text
            x={textContainerWidth - 8}
            y={spreadYOffset}
            fill='#000'
            style={{
              fontFamily: spreadFontFamily,
              fontSize: spreadFontSize,
              textAnchor: 'end'
            }}
          >
            {teamOneSpread}
          </text>
        )}

        {/* Team One Prediction */}
        <text
          x={textContainerWidth - 8}
          y={height - 8}
          fill='#000'
          style={{
            fontFamily: predictionFontFamily,
            fontSize: predictionFontSize,
            textAnchor: 'end'
          }}
        >
          {(teamOneWinProbability * 100).toFixed()}%
        </text>

        {/* Team One Name */}
        <text
          x={0}
          y={height + 16}
          fill={teamOneColor}
          style={{
            fontFamily: 'Graduate',
            fontSize: teamNameFontSize,
            textAnchor: 'start'
          }}
        >
          {teamOneName}
        </text>

        {/* Team Two Spread */}
        {teamTwoSpread && teamTwoSpread < teamOneSpread && (
          <text
            x={(width - textContainerWidth) + 8}
            y={spreadYOffset}
            fill='#000'
            style={{
              fontFamily: spreadFontFamily,
              fontSize: spreadFontSize,
              textAnchor: 'start'
            }}
          >
            {teamTwoSpread}
          </text>
        )}

        {/* Team Two Prediction */}
        <text
          x={(width - textContainerWidth) + 8}
          y={height - 8}
          fill='#000'
          style={{
            fontFamily: predictionFontFamily,
            fontSize: predictionFontSize,
            textAnchor: 'start'
          }}
        >
          {(teamTwoWinProbability * 100).toFixed()}%
        </text>

        {/* Team Two Name */}
        <text
          x={width}
          y={height + 16}
          fill={teamTwoColor}
          style={{
            fontFamily: 'Graduate',
            fontSize: teamNameFontSize,
            textAnchor: 'end'
          }}
        >
          {teamTwoName}
        </text>

        {/* Grid Lines */}
        {[...Array(9).keys()].map((_, i) => {
          const offset = textContainerWidth + (i * gridLineInterval);

          return (
            <line
              key={i}
              x1={offset}
              x2={offset}
              y1={0}
              y2={xAxisPosition}
              stroke={axisColor}
              strokeWidth={axisStrokeWidth}
            />
          );
        })}

        {/* Background bar */}
        <rect
          x={textContainerWidth}
          y={barYDepth}
          width={backgroundRectWidth}
          height={barYHeight}
          fill={backgroundBarFill}
        />

        {/* Left Bar */}
        <rect
          x={teamOneRectXOffset}
          y={barYDepth}
          width={teamOneRectWidth}
          height={barYHeight}
          fill={teamOneColor}
        />

        {/* Right Bar */}
        <rect
          x={midpointX}
          y={barYDepth}
          width={teamTwoRectWidth}
          height={barYHeight}
          fill={teamTwoColor}
        />

        {/* Y Axis */}
        <line
          x1={midpointX}
          x2={midpointX}
          y1={0}
          y2={xAxisPosition}
          stroke={axisColor}
          strokeWidth={axisStrokeWidth}
        />

        {/* X Axis */}
        <line
          x1={0}
          x2={width}
          y1={xAxisPosition}
          y2={xAxisPosition}
          stroke={axisColor}
          strokeWidth={axisStrokeWidth}
        />
      </svg>
    </div>
  );
};

export default BarV2;
