import React from 'react';
import {BarV2} from '../../components/charts/Bar';
import {Tooltip, Icon} from 'antd';

function formatWinningTeamSummary(winningTeamName, winningTeamProbability) {
  const rounded = (winningTeamProbability * 100).toFixed();
  let article = 'a';

  if (rounded.charAt(0) === '8' || rounded === '11' || rounded === '18') {
    article = 'an';
  }

  return `${winningTeamName} is ${article} ${rounded}% predicted winner`;
}

function formatSpread(spread) {
  const s = spread.toString().split('.');
  let article = 'a';

  if (s[0] === '8' || s[0] === '11' || s[0] === '18') {
    article = 'an';
  }

  return ` and ${article} ${spread} point favorite`;
}

const ChartOutputs = (props) => {
  const {predictions} = props;

  return (
    <div className='matchup-predictor-form--chart-outputs'>
      {predictions.map((prediction) => {
        const {
          description,
          modelName,
          teamOneColor,
          teamOneName,
          teamOneWinProbability,
          teamTwoColor,
          teamTwoName,
          teamTwoWinProbability,
          teamOneSpread,
          teamTwoSpread,
          totalPoints
        } = prediction;

        const winningTeamName = teamOneWinProbability >= teamTwoWinProbability
          ? teamOneName : teamTwoName;
        const winningTeamProbability = teamOneWinProbability >= teamTwoWinProbability
          ? teamOneWinProbability : teamTwoWinProbability;

        let predictionSummary = formatWinningTeamSummary(winningTeamName, winningTeamProbability);

        if (teamOneSpread && teamTwoSpread) {
          let spread = teamOneWinProbability >= teamTwoWinProbability
            ? Math.abs(teamOneSpread) : Math.abs(teamTwoSpread);
          predictionSummary += formatSpread(spread);
        }
        predictionSummary += ` on a neutral court.`;

        return (
          <div className='chart-prediction-container' key={modelName}>
            <h3>
              {modelName} {' '}
              <Tooltip
                placement='left'
                title={description}
              >
                <Icon type='info-circle'/>
              </Tooltip>
            </h3>

            <p>{predictionSummary}</p>

            {totalPoints && (
              <div className='total-points'>
                <div className='total-points--separator'></div>
                <p className='total-points--title'>
                  PREDICTED TOTAL GAME POINTS
                </p>
                <p className='total-points--value'>{totalPoints.toFixed()}</p>
              </div>
            )}

            <BarV2
              teamOneColor={teamOneColor}
              teamOneName={teamOneName}
              teamOneSpread={teamOneSpread}
              teamOneWinProbability={teamOneWinProbability}
              teamTwoColor={teamTwoColor}
              teamTwoName={teamTwoName}
              teamTwoSpread={teamTwoSpread}
              teamTwoWinProbability={teamTwoWinProbability}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChartOutputs;
