import React from 'react';
import DefaultSection from './DefaultSection';

import './FeaturedModelSection.less';

const FeaturedModelSection = ({children}) => {
  return (
    <DefaultSection sectionClassName='featured-model'>
      {children}
    </DefaultSection>
  );
};

export default FeaturedModelSection;

