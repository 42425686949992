const blogs = [
  {
    href: 'https://evolytics.com/blog/evolytics-2020-college-basketball-prediction-results-are-live/',
    imgSrc: 'Caleb-blog-1.png',
    imgAlt: 'A screenshot of a Tableau dashboard showing the featured model success rate in game picks.',
    title: 'Evolytics 2020 College Basketball Prediction Results Are Live'
  },
  {
    href: 'https://www.evolytics.com/blog/march-madness-tableau-dashboard-enhances-machine-learning-model/',
    imgSrc: 'college-basketball-game-predictor-360x.jpg',
    imgAlt: 'A basketball court and crowd',
    title: 'March Madness Tableau Dashboard Enhances Machine Learning Model'
  },
  {
    href: 'https://www.evolytics.com/blog/evolytics-launches-college-basketball-game-predictor/',
    imgSrc: '01-app-launch-360x.jpg',
    imgAlt: 'A basketball hovering over a basketball court and bracket',
    title: 'Evolytics Launches College Basketball Game Predictor'
  }
];

export default blogs;

