const faqs = [
  {
    question: 'How does the tool work?',
    answers: [
      'Select two teams in the fields above and find out the likelihood '
      + 'one team would win in a matchup on a neutral court.',
      'Everyone has a different strategy when filling out their tournament bracket. '
      + 'Some people just pick their alma mater, while others make their picks according '
      + 'to their favorite mascots or color schemes. Evolytics relies on data.',
      'While nothing in March is guaranteed, the win probability figures presented '
      + 'by our predictor will give you a good idea of the likely outcome of each game. '
      + 'Evolytics lets you take the hassle out of picking that perfect 5/12 upset '
      + 'by trusting the numbers.'
    ]
  },

  {
    question: 'Does the tool help my bracket?',
    answers: [
      'Yes. Predictive analytics will help you get a leg up on your office '
      + 'bracket pool by picking the likeliest winner in each tournament '
      + 'matchup.',
      'Pair teams against their potential matchups throughout the tournament '
      + 'and select your cinderella picks before the first game tips off.'
    ]
  },

  {
    question: 'What are the odds of a perfect bracket?',
    answers: [
      'The odds of a perfect bracket are long. Really long.'
      + '1-in-9,223,372,036,854,775,808-long. That\'s 9.2 quintillion.',
      'While our tool won\'t guarantee a perfect bracket, it can certainly '
      + 'improve your odds of winning your bracket pool. '
      + 'All you need to do is pick a team, match them up against all '
      + 'possible opponents during the tournament, and make more '
      + 'informed decisions on your bracket. After that, it’s all up to the '
      + 'basketball gods.'
    ]
  },

  {
    question: 'Does the tool factor in current injuries?',
    answers: [
      'Not at this time. Individual player stats and related items are in '
      + 'the queue for future iterations.'
    ]
  }
];

export default faqs;

