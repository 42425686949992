import React from 'react';
import {Collapse} from 'antd';
import DefaultSection from './DefaultSection';

import './FAQSection.less';

const Panel = Collapse.Panel;

const renderAnswers = (paragraphs) =>
  paragraphs.map((paragraph, index) => {
    return (
      <p key={index}>{paragraph}</p>
    );
  });

const FAQSection = ({faqs}) => {
  return (
    <DefaultSection sectionClassName='faqs-section'>
      <h2>FAQs</h2>

      <Collapse accordion>
        {faqs.map(faq => {
          const {question, answers} = faq;

          return (
            <Panel
              forceRender={true}
              header={question}
              key={question}
            >
              {renderAnswers(answers)}
            </Panel>
          );
        })}
      </Collapse>
    </DefaultSection>
  );
};

export default FAQSection;

