const MultiModelPrediction = {
  '@@metadata': {
    allowNulls: true
  },
  model_name: {
    type: 'string',
    required: true
  },
  team_one_win_probability: {
    type: 'number',
    required: true
  },
  team_two_win_probability: {
    type: 'number'
  }
};

export default MultiModelPrediction;

